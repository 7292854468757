(function(angular) {
    'use strict';

    angular.module("app")
        .directive("cFieldPanel", cFieldPanel)
        .directive("cFieldPanelCrop", cFieldPanelCrop)
        .directive("cFieldPanelCropAdd", cFieldPanelCropAdd)
        .directive("cAsideThresholds", cAsideThresholds)
        .directive('vHarvestingDate', vHarvestingDate)
        .directive('vSeedingDate', vSeedingDate);

    function processFieldVariety(_crop, _i18n, _logicService, gettextCatalog) {
        var crop = _crop;

        crop.type = crop.variety.crop.name.toLowerCase();
        crop.variety.crop.formName = _i18n.getStringFromBundleLat(crop.variety.crop.name);
        crop.variety.formName = _i18n.getStringFromBundleEn(crop.variety.name);
        crop.from = moment.utc(crop.seedingDate);
        crop.to = _logicService.cropTo(crop);

        _.each(crop.tasks, function (task) {
            task.from = moment.utc(task.from);
            task.to = moment.utc(task.to);
            task.status_label = _i18n.getString("task.status." + task.status);
            if (task.type == 'spray') {
                if (task.sprayType == 'PARTIAL') {
                    task.label = gettextCatalog.getString("Spray");
                } else {
                    task.label = gettextCatalog.getString("Full Spray");
                }
            }
        });

        if (!_.isUndefined(crop.tasks)) {
            var marginRight = 10;
            var marginTop = 2;

            for (var i = 0, len = crop.tasks.length; i < len; i++) {
                var prev = crop.tasks[i - 1];
                var current = crop.tasks[i];

                crop.tasks[i].zIndex = 10;

                if (prev && current.from.isSame(prev.from)) {
                    crop.tasks[i].marginRight = prev.marginRight ? prev.marginRight + marginRight : marginRight;
                    crop.tasks[i].marginTop = prev.marginTop ? prev.marginTop + marginTop : marginTop;
                }

                if (prev) {
                    crop.tasks[i].zIndex = prev.zIndex + 1;
                }
            }
        }
        return crop;
    }

    function cFieldPanel($rootScope, _v, _view, _apiService, Notification, _i18n, _calendar, _flashService,
                         _logicService, gettextCatalog, _diseasesForecastService,  $timeout, $compile) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: "t-c-field-panel",
            scope: true,
            controllerAs: 'vm',
            controller: function($scope) {
                var vm = this;

                vm.isLoading = true;

                vm.isGranularityStages = _calendar.isGranularityStages;

                $rootScope.$on("_view:urlReady", () => init(true) );
                $rootScope.$on("_view:fieldChanged", () => init(false) );
                $rootScope.$on("_view:addCropMonitorChanged", () => init(false) );
                $rootScope.$on("_view:editSprayChanged", () => init(false) );

                $rootScope.$on("_view:panelFieldChanged", () => {
                    if (_view.getPanelField()) {
                        vm.selectedField = vm.fields.find(field => field.id == _view.getPanelField().id);
                    }
                });

                $rootScope.$on("_crop:openSettings", () => {
                    vm.settingsShown = true;
                });

                $rootScope.$on("_crop:closeSettings", () => {
                    vm.settingsShown = false;
                });

                vm.selectField = function(field) {
                    _flashService.put('panRequiredEvent', true);
                    _v.change({set: {pfield: field.id}});
                };

                function initForecast() {
                    var now = moment.utc();
                    var currentCrop = _logicService.findCropNow(now, now, vm.activeField.crops);
                    if (currentCrop) {
                         _diseasesForecastService.model(currentCrop).then( function (result) {
                             currentCrop.forecastModel = _.filter(_.map(result, function (r) {
                                return {disease: r.disease, thresholdsOverlap: r.thresholdsOverlap};
                             }), function (it) {
                                return _.size(it.thresholdsOverlap) > 0;
                             });
                             _view.setForecastAll(result);
                             var forecastDiseaseId = _flashService.get("forecastDiseaseId");
                             if (forecastDiseaseId) {
                                 _view.setForecastData(_.find(_view.getForecastAll(), function (e) { return e.disease.id == forecastDiseaseId}));
                                 _view.setForecastOpened(true);
                             }
                         });
                    }
                }

                function init(urlReady) {
                    vm.isLoading = true;
                    vm.fields = [];
                    vm.activeField = null;
                    vm.isActiveTask = null;
                    vm.selectedField = null;
                    vm.activeTask = null;

                    if (_view.isFarmAndFieldSelected() || _view.isFieldSelected()) {
                        vm.activeField = _view.getCurrentField();

                        _apiService.fieldCrops(_view.getCurrentAccount(), vm.activeField.id).then(function(d) {
                            vm.activeField.crops = [];

                            _.each(d.data, function(crop) {
                                vm.activeField.crops.push(processFieldVariety(crop, _i18n, _logicService, gettextCatalog));
                            });

                            initForecast();

                            vm.activeField.crops.sort(function (a, b) {
                                return moment.utc(a.from).diff(moment.utc(b.from));
                            });

                            _.size(vm.activeField.crops) > 0 ? _view.setFieldCrops(true): _view.setFieldCrops(false);

                            vm.isLoading = false;
                            panelReady(urlReady);
                        }, function() {
                            vm.isLoading = false;
                            panelReady(urlReady);
                            Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                        });
                    } else if (_view.isFarmSelected()) {
                        vm.fields = _view.getCurrentFarm().fields;

                        if (_calendar.isGranularityStages()) {
                            _calendar.fallbackToMonthGranularity();
                        }

                        if (_view.isPanelFieldSelected()) {
                            vm.selectedField = vm.fields.find(field => field.id == _view.getPanelField().id);
                        }

                        _apiService.farmCrops(_view.getCurrentAccount(), _view.getCurrentFarm().id).then(function(d) {
                            var groupByField = _.groupBy(d.data, function(s){return s.field.id});

                            for (var key in groupByField) {
                                if (groupByField.hasOwnProperty(key)) {
                                    (function(fieldID) {
                                        var f = vm.fields.find(field => field.id == fieldID);
                                        if (f) {
                                            f.crops = [];
                                            groupByField[fieldID].forEach(crop => f.crops.push(processFieldVariety(crop, _i18n, _logicService, gettextCatalog)));

                                            f.crops.sort(function (a, b) {
                                                return moment.utc(a.from).diff(moment.utc(b.from));
                                            });
                                        }
                                    }(key));
                                }
                            }

                            vm.isLoading = false;
                            panelReady(urlReady);
                        }, function() {
                            vm.isLoading = false;
                            panelReady(urlReady);
                            Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                        });
                    } else {
                        vm.isLoading = false;
                        panelReady(urlReady);
                    }
                }

                function panelReady(urlReady) {
                    _view.refreshMinHeight();
                    $timeout( function () {
                        if (urlReady) {
                            $rootScope.$broadcast('_c-field-panel:init');
                        } else {
                            $rootScope.$broadcast('_c-field-panel:update');
                        }
                    });
                }

                vm.$div = null;

                vm.addTaskPopup = {
                    selector: 'task',
                        opened: false,
                        close: function() {
                        // wait for reflow
                        //$timeout(() => vm.addTaskPopup.opened = false);
                        vm.addTaskPopup.opened = false;
                        if (vm.$div) {
                            vm.$div.remove();
                        }
                    },
                    open: function() {
                        // wait for reflow
                        $timeout(() => vm.addTaskPopup.opened = true);
                    },
                    ok: function() {
                        _flashService.put('taskDate', vm.addTaskPopup.date);
                        if (vm.addTaskPopup.selector == 'task') {
                            if (_view.is.map()) {
                                _v.change({set: {addCropMonitor: true, viewCropMonitor: undefined}});
                            } else {
                                _v.change({set: {addCropMonitor: true, viewCropMonitor: undefined, view: 'm'}});
                            }
                        } else {
                            if (_view.is.map()) {
                                _v.change({set: {editSpray: -1}});
                            } else {
                                _v.change({set: {editSpray: -1, view: 'm'}});
                            }
                        }
                        vm.addTaskPopup.close();
                    }
                };

                vm.addTask = function (e) {

                    // find out date on clicked position
                    _calendar.getDateForPositionPromise(e.pageX).then(function(date) {
                        vm.addTaskPopup.date = date;
                    });

                    // Add popover
                    var $target = $("div.f-panel-single-field");

                    if (vm.$div) {
                        vm.addTaskPopup.close();
                    }

                    vm.$div = $compile('<div uib-popover-template="\'t-c-field-panel-crop-addTask\'" popover-class="popup-crop-addTask" popover-append-to-body="true" popover-placement="top" popover-trigger="none" popover-is-open="vm.addTaskPopup.opened"></div>')($scope);
                    vm.$div.css('position', 'absolute');
                    $target.append(vm.$div);

                    vm.$div.css({
                        left: e.clientX,
                        top: e.offsetY
                    });

                    vm.addTaskPopup.open();
                }
            }
        };
    }

    function cFieldPanelCrop($rootScope, $timeout, _v, _view, _apiService, _calendar, $uibModal, Notification,
                             _i18n, $compile, _flashService, _logicService, $q, gettextCatalog) {
        return {
            restrict: "E",
            templateUrl: "t-c-field-panel-crop",
            replace: true,
            scope: {
                crop: "=",
                f: "=",
                loading: "=",
                at: '<'
            },
            bindToController: true,
            controllerAs: 'vm',
            controller: function($scope) {
                var vm = this;

                vm.view = _view;

                $rootScope.$on("_calendar:width-changed", function() {
                    updateModel();
                });

                $rootScope.$on("_calendar:changed", () => {
                    updateModel();
                });

                function updateModel() {
                    vm.granularity = _calendar.granularity;
                    if (_calendar.isGranularityStages()) {
                        vm.stageLabels = [];
                        _calendar.itemsPromise.then(function(items) {
                            _.each(items, function(item){
                                if (item) {
                                    var leftPromise = _calendar.getPositionForDatePromise(item.date).then(function(pos) {
                                        return pos;
                                    });

                                    var rightPromise = _calendar.getPositionForDatePromise(item.dateFinish).then(function(pos) {
                                        return pos;
                                    });

                                    $q.all({left: leftPromise, right: rightPromise, name: $q.when(item.stageName)}).then(function(result) {
                                        vm.stageLabels.push({name: result.name, left: result.left, right: result.right});
                                    });
                                }
                            });
                        });
                    }
                }

                updateModel();

                vm.diseaseWarningClick = function(d) {
                    if (_view.is.map()) {
                        var url = _v.getUrl({set: {view: 'w', field: _view.getCurrentField().id,
                            pfield: undefined,
                            farm: _view.getCurrentFarm() ? _view.getCurrentFarm().id : undefined,
                            granularity: 'w', from: d.thresholdsOverlap[0].from.format("YYYY-MM-DDTHH")}});
                        _flashService.put('forecastDiseaseId', d.disease.id);
                        window.location.href = url;
                    }
                    if (_view.is.weather()) {
                        _view.setForecastData(_.find(_view.getForecastAll(), function (e) { return e.disease.id == d.disease.id}));
                        _view.setForecastOpened(true);
                    }
                };

                vm.openDiseaseThresholdChart = function(disease) {
                    _view.setForecastData(_.find(_view.getForecastAll(), function (e) { return e.disease.id == disease.id}));
                    _view.setForecastOpened(true);
                };

                // Open task page
                vm.openTask = function(task) {
                    var isOpened = _view.is.viewSpray() || _view.is.viewCropMonitor() || _view.is.editSpray();
                    var at = vm.at && parseInt(vm.at);

                    if (isOpened && (task.id === at)) {
                        _v.change({set: {viewSpray: undefined, viewCropMonitor: undefined, addCropMonitor: undefined, editSpray: undefined, viewPhotoNote: undefined}});
                    } else {
                        _toggleStatesOfTaskScreens(task);
                    }

                    function _toggleStatesOfTaskScreens(task) {
                        if (task.type === 'spray') {
                            _v.change({set: {viewSpray: task.id, editSpray: undefined, viewCropMonitor: undefined, addCropMonitor: undefined, viewPhotoNote: undefined}});
                        } else {
                            _v.change({set: {viewCropMonitor: task.id, addCropMonitor: undefined, viewSpray: undefined, editSpray: undefined, viewPhotoNote: undefined}})
                        }
                    }
                };

                // Open settings modal
                vm.openSettings = function(e) {
                    if (!$rootScope.can('manageCrops')) {
                        return;
                    }

                    var $target = $(e.target);
                    var $deleteButton = 'f-panel-crops-crop-label-delete';

                    if ($target.hasClass($deleteButton) || $target.closest('.' + $deleteButton).length) {
                        return; // delete crop
                    }

                    $rootScope.$broadcast("_crop:openSettings");

                    vm.settingsShown = true;

                    var $uibModalInstance = $uibModal.open({
                        openedClass: 'modal-open modal-cropSettings',
                        templateUrl: 'modal-cropSettings',
                        controllerAs: 'vm',
                        controller: ModalCropSettingsController,
                        resolve: {
                            crop: angular.copy(vm.crop),
                            crops: () => vm.f.crops,
                            fieldId: null
                        }
                    });

                    $uibModalInstance.result.then(function (crop) {
                        vm.isLoading = true;

                        _apiService.updateCrop(_view.getCurrentAccount(), crop).then(
                            response => {
                                _flashService.put("successMessage", _i18n.getString("crops.notifications.updateCrop.success"));
                                localStorage.setItem('noSplashScreen', true);
                                window.location.href = _v.getUrl({set: {}});
                            },
                            () => {
                                vm.isLoading = false;
                                Notification.error(_i18n.getString("crops.notifications.updateCrop.error"));
                            }
                        );

                        $rootScope.$broadcast("_crop:closeSettings");
                        vm.settingsShown = false;
                    }, function() {
                        $rootScope.$broadcast("_crop:closeSettings");
                        vm.settingsShown = false;
                    });
                };

                vm.deleteCrop = function() {
                    var cropToDelete = vm.crop;

                    var $uibModalInstance = $uibModal.open({
                        openedClass: 'modal-open modal-cropDelete',
                        templateUrl: 'modal-cropDelete',
                        controllerAs: 'vm',
                        resolve: {
                            crop: vm.crop
                        },
                        controller: function($uibModalInstance, _logicService, crop) {
                            var vm = this;
                            vm.crop = crop;
                            vm.allowToDelete = _.size(vm.crop.tasks) == 0;

                            vm.ok = function() {
                                $uibModalInstance.close();
                            };

                            vm.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }
                    });

                    $uibModalInstance.result.then(function () {
                        vm.isLoading = true;

                        _apiService.deleteCrop(_view.getCurrentAccount(), cropToDelete).then(
                            () => {
                                _flashService.put("successMessage", _i18n.getString("crops.notifications.deleteCrop.success"));
                                localStorage.setItem('noSplashScreen', true);
                                window.location.href = _v.getUrl({set: {}});
                            },
                            () => {
                                vm.isLoading = false;
                                Notification.error(_i18n.getString("crops.notifications.deleteCrop.error"));
                            }
                        );
                    });
                };

                vm.isGranularityStages = _calendar.isGranularityStages;
            }
        };
    }

    function cAsideThresholds ($rootScope, $uibModal, $timeout, $q, _apiService, Notification, _view, _i18n, _logicService, dangerThresholds) {
        return {
            restrict: "E",
            templateUrl: "t-c-aside-thresholds",
            bindToController: true,
            replace: true,
            controllerAs: 'vm',
            controller: function ($scope) {
                var vm = this;

                vm.isLoading = true;

                vm.sowingId = _view.getViewThresholds().id;
                vm.type = _view.getViewThresholds().type;
                vm.thresholds = {};

                vm.currentCrop = null;
                vm.currentField = null;

                vm.activeDanger = null;
                vm.settings = null;

                vm.isModalOpened = false;

                $scope.$on('_view:closeAllScreens', function () {
                    if (_view.is.viewThresholds()) {
                        closeThresholds();
                    }
                });

                vm.insectThresholdUnit = "NUMBER_INSECTS"; //NUMBER_INSECTS, PERCENT_PLANTS, NUMBER_PLANTS

                if (vm.type === 1) initThresholdsList(_apiService.getInsects, _apiService.insectThresholdsFlags);
                else if (vm.type === 2) initThresholdsList(_apiService.getDiseases, _apiService.diseaseThresholdsFlags);
                else if (vm.type === 3) initThresholdsList(_apiService.getWeeds, _apiService.weedThresholdsFlags);

                //used only in insects thresholds
                vm.setUnit = function(u) {
                    dangerThresholds.clearCanvas();
                    vm.isLoading = true;
                    vm.insectThresholdUnit = u;
                    vm.thresholdKey = getThresholdKey();

                    if (vm.thresholds && vm.activeDanger && !vm.thresholds[vm.thresholdKey]) {
                        if (vm.type === 1) loadThresholds(_apiService.insectThresholds);
                        else if (vm.type === 2) loadThresholds(_apiService.diseaseThresholds);
                        else if (vm.type === 3) loadThresholds(_apiService.weedThresholds);
                    } else {
                        vm.isLoading = false;
                        drawGraph(vm.activeDanger, vm.currentCrop, vm.thresholds);
                    }
                };

                // Select insects
                vm.selectDanger = function (danger) {
                    dangerThresholds.clearCanvas();
                    vm.isLoading = true;
                    vm.activeDanger = danger;
                    vm.thresholdKey = getThresholdKey();

                    if (vm.thresholds && vm.activeDanger && !vm.thresholds[vm.thresholdKey]) {
                        if (vm.type === 1) loadThresholds(_apiService.insectThresholds);
                        else if (vm.type === 2) loadThresholds(_apiService.diseaseThresholds);
                        else if (vm.type === 3) loadThresholds(_apiService.weedThresholds);
                    } else {
                        vm.isLoading = false;
                        drawGraph(vm.activeDanger, vm.currentCrop, vm.thresholds);
                    }
                };

                vm.resetThreshold = function (key) {
                    vm.isLoading = true;
                    var resetFun;
                    if (vm.type === 1) { resetFun = _apiService.insectThresholdsReset(_view.getCurrentAccount(), vm.activeDanger.id, vm.insectThresholdUnit, vm.currentCrop.variety.crop.id); }
                    if (vm.type === 2) { resetFun = _apiService.diseaseThresholdsReset(_view.getCurrentAccount(), vm.activeDanger.id, vm.currentCrop.variety.crop.id); }
                    if (vm.type === 3) { resetFun = _apiService.weedThresholdsReset(_view.getCurrentAccount(), vm.activeDanger.id, vm.currentCrop.variety.crop.id); }
                    resetFun.then(
                        function () {
                            dangerThresholds.clearCanvas();
                            vm.thresholds = {};
                            if (vm.type === 1) initThresholdsList(_apiService.getInsects, _apiService.insectThresholdsFlags);
                            else if (vm.type === 2) initThresholdsList(_apiService.getDiseases, _apiService.diseaseThresholdsFlags);
                            else if (vm.type === 3) initThresholdsList(_apiService.getWeeds, _apiService.weedThresholdsFlags);
                        }, err => {
                            vm.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                    });
                };

                // Close and cancel buttons to aside menu.
                vm.close = closeAside;
                vm.cancel = closeAside;

                // Save insect thresholds
                vm.save = saveThresholds;

                function getThresholdKey() {
                    return vm.type === 1 ? vm.activeDanger.id + '-' + vm.insectThresholdUnit : vm.activeDanger.id;
                }

                function loadThresholds(getThresholds) {
                  var account = _view.getCurrentAccount();
                  getThresholds(account, vm.currentCrop.variety.crop.id, vm.activeDanger.id).then(res => {
                      vm.thresholdKey = getThresholdKey();

                      vm.thresholds[vm.thresholdKey] = {
                          wasChanged: false,
                          custom: false
                      };

                      if (!_.isEmpty(res.data)) {
                          vm.thresholds[vm.thresholdKey].values = [];
                          _.each(res.data, function (threshold) {
                              if (vm.type != 1 || threshold.thresholdUnit == vm.insectThresholdUnit) {
                                  vm.thresholds[vm.thresholdKey].values.push({
                                      value: threshold.value,
                                      cropStageId: threshold.cropStage.id,
                                      cropStageName: threshold.cropStage.name
                                  });
                                  if (threshold.custom) {
                                      vm.thresholds[vm.thresholdKey].custom = true;
                                  }
                              }
                          });

                          drawGraph(vm.activeDanger, vm.currentCrop, vm.thresholds);
                      }

                      vm.isLoading = false;
                  }, err => {
                      vm.isLoading = false;
                      Notification.error(_i18n.getString("common.notifications.apiError"));
                  });
                }

                function initThresholdsList(getGuide, getFlags) {
                    var account = _view.getCurrentAccount();
                    vm.currentCrop = _view.getViewThresholds().crop;
                    $q.all({
                        listResponse: getGuide(account),
                        flagsResponse: getFlags(account, vm.currentCrop.variety.crop.id)
                    }).then(
                        function (values) {
                            vm.dangersGuide = _.map(values.listResponse.data, function (e) {
                                e.formName = _i18n.getStringFromBundleLat(e.name);
                                e.hasCustom = values.flagsResponse.data[e.id].hasCustom;
                                e.allNA = values.flagsResponse.data[e.id].allNA;
                                e.hasDanger = values.flagsResponse.data[e.id].hasDanger;
                                return e;
                            });
                            vm.dangersGuide = _.sortBy(vm.dangersGuide, e => { return e.formName });
                            if (vm.activeDanger) {
                                vm.selectDanger(vm.activeDanger);
                            } else {
                                if (_view.getViewThresholds().insectId) {
                                    vm.selectDanger(_.find(vm.dangersGuide, function (e) { return e.id == _view.getViewThresholds().insectId; }));
                                } else {
                                    vm.selectDanger(vm.dangersGuide[0]);
                                }
                            }
                        }, function () {
                            vm.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        }
                    );
                }

                function drawGraph (danger, crop, thresholds) {
                    vm.settings = {
                        thresholdKey: getThresholdKey(),
                        maxThreshold: vm.type === 1 ? getMaxThresholdForInsect(danger) : danger.maxThreshold,
                        crop: crop,
                        thresholds: thresholds
                    };

                    dangerThresholds.clearCanvas();
                    dangerThresholds.init(vm.settings);
                }

                function getMaxThresholdForInsect(insect) {
                    if (vm.insectThresholdUnit == "NUMBER_INSECTS") {
                        return insect.maxThreshold;
                    } else if (vm.insectThresholdUnit == "PERCENT_PLANTS") {
                        return 100;
                    } else if (vm.insectThresholdUnit == "NUMBER_PLANTS") {
                        return insect.maxThresholdPlants;
                    }
                }

                $rootScope.$on('_calendar:width-changed', function() {
                    dangerThresholds.init(vm.settings);
                });

                function closeAside () {
                    var $uibModalInstance = $uibModal.open({
                        openedClass: 'modal-open modal-cancelTresholdsEditor',
                        templateUrl: 'modal-cancelTresholdsEditor',
                        controllerAs: 'vm',
                        controller: function($uibModalInstance) {
                            var vm = this;

                            // Close without Saving
                            vm.close = function() {
                                $uibModalInstance.close('close');
                            };

                            // Save
                            vm.save = function() {
                                $uibModalInstance.close('save');
                            };

                            // Cancel
                            vm.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }
                    });

                    $uibModalInstance.result.then(function (msg) {
                        if (msg === 'save') {
                            saveThresholds();
                        }else if (msg === 'close') {
                            if (!(_view.is.viewSpray() || _view.is.viewCropMonitor())) {
                                var $uibModalInstance = $uibModal.open({
                                    openedClass: 'modal-open modal-cropSettings',
                                    templateUrl: 'modal-cropSettings',
                                    controllerAs: 'vm',
                                    controller: ModalCropSettingsController,
                                    resolve: {
                                      crop: angular.copy(vm.currentCrop),
                                      crops: () => null,
                                      fieldId: null
                                    }
                                });
                            }
                        }
                        closeThresholds();
                    });
                }

                function saveThresholds () {
                    vm.isLoading = true;
                    var promises = [];

                    _.each(vm.settings.thresholds, (threshold, danger) => {
                        if (threshold.wasChanged) {

                            var account = _view.getCurrentAccount(),
                               thresholdsToSave = null;

                            var result = {
                                crop: {
                                   id: vm.currentCrop.variety.crop.id
                                },
                                thresholds: threshold.values
                            };

                            if (vm.type === 1) {
                                result.thresholdUnit = danger.split("-")[1];
                                result.insect = {id: parseInt(danger.split("-")[0])};
                                thresholdsToSave = _apiService.saveInsectThresholds(account, result.insect.id, result);
                            } else if (vm.type  === 2) {
                                result.disease = {id: parseInt(danger)};
                                thresholdsToSave = _apiService.saveDiseaseThresholds(account, result.disease.id, result);
                            } else if (vm.type === 3) {
                                result.weed = {id: parseInt(danger)};
                                thresholdsToSave = _apiService.saveWeedThresholds(account, result.weed.id, result);
                            }

                            promises.push(thresholdsToSave);
                        }
                    });

                    $q.all(promises).then(response => {
                        vm.isLoading = false;

                        if (!(_view.is.viewCropMonitor())) {
                            var $uibModalInstance = $uibModal.open({
                                  openedClass: 'modal-open modal-cropSettings',
                                  templateUrl: 'modal-cropSettings',
                                  controllerAs: 'vm',
                                  controller: ModalCropSettingsController,
                                  resolve: {
                                    crop: angular.copy(vm.currentCrop),
                                    crops: () => vm.fieldCrops,
                                    fieldId: null
                                  }
                            });
                        } else {
                          $rootScope.$broadcast('_view:updateViewCropMonitor');
                        }
                        closeThresholds();
                    }, err => {
                        vm.isLoading = false;
                        Notification.error(_i18n.getString('common.notifications.apiError'));
                    });
                }

                function closeThresholds () {
                    _view.setViewThresholds(null);

                    $rootScope.$broadcast('aside:closed');
                    $rootScope.$broadcast('_crop:openedAside');
                }

            }
        }
    }


    /**
     * Controller for modal "Crop Settings"
     *
     * @constructor
     */
    function ModalCropSettingsController($rootScope, $scope, $uibModalInstance, _view, _apiService, crop, crops, fieldId, gettextCatalog, _i18n, _logicService, _formatService) {
        var vm = this;

        vm.crop = crop;
        vm.crops = crops;

        vm.dateFormat = _formatService.getDatePickerFormatForDate();

        // Create a new crop
        if (fieldId) {
            vm.crop = {
                "field" : { "id" : fieldId },
                "variety" : { "id" : null },
                "seedingDate" : null,
                "plannedHarvestingDate" : null,
                "seedingRate" : 0.1,
                "rowWidth" : 0.8,
                "plannedYield" : 0,
                "actualYield" : 0
            };
        }

        /**
         * Crop varieties
         */
        vm.varietiesSelector = null;
        if (vm.crop.variety.id) {
            vm.currentVariety = vm.crop.variety;
        }
        if (vm.crop.variety.crop) {
            vm.crop.variety.crop.formName = _i18n.getStringFromBundleLat(vm.crop.variety.crop.name);
            vm.crop.variety.formName = _i18n.getStringFromBundleEn(vm.crop.variety.name);
        }



        _apiService.getVarieties(_view.getCurrentAccount()).then(function(response){
            vm.varietiesSelector = _.filter(response.data, function (e) { //remove wheat
                return true;
            });
            _.each(vm.varietiesSelector, function (v) {
                v.crop.formName = _i18n.getStringFromBundleLat(v.crop.name);
                v.formName = _i18n.getStringFromBundleEn(v.name);
            });
        });

        vm.changeVariety = function() {
            vm.crop.variety = vm.varietiesSelector.find(v => v.id == vm.currentVariety.id);

            processFieldVariety(vm.crop, _i18n, _logicService, gettextCatalog);
        };

        /**
         * Common calendar options
         */
        var firstDayOfWeek = _view.getCurrentAccountInfo().settings.firstDayOfWeek || 1;

        var calendar = {
            opened: false,
            inlineOptions: {
                startingDay: firstDayOfWeek // 0-6 (Sunday to Saturday)
            },
            dateOptions: {
                formatYear: 'yy'
            }
        };

        /**
         * Seeding Date
         */
        vm.seedingDate = _.extend({}, calendar, {
            date: _logicService.strToJavascriptDate(vm.crop.seedingDate),
            clear: () => vm.seedingDate.date = null
        });

        /**
         * Planned Harvesting Date
         */
        vm.plannedHarvestingDate = _.extend({}, calendar, {
            date: _logicService.strToJavascriptDate(vm.crop.plannedHarvestingDate),
            clear: () => vm.plannedHarvestingDate.date = null
        });


        /**
         * Actual Harvesting Date
         */
        vm.actualHarvestingDate = _.extend({}, calendar, {
            date: _logicService.strToJavascriptDate(vm.crop.actualHarvestingDate),
            clear: () => vm.actualHarvestingDate.date = null
        });

        /**
         * Form validation
         *
         * @param {object} field
         * @returns {string}
         */
        vm.getError = function(field) {
            var message = '';

            if (!_.isEmpty(field)) {
                if (field.$error.pattern || field.$error.number) {
                    message = gettextCatalog.getString("This field must be in the following format: '0.00'");
                } else if (field.$error.date) {
                    message = gettextCatalog.getString("This field must be in the following format: MM/dd/yyyy");
                } else if (field.$error.min) {
                    if (field.$name == "seedingRate") {
                        message = gettextCatalog.getString("Value must be greater than 0");
                    }
                } else if (field.$error.required) {
                    message = gettextCatalog.getString("This field is required");
                } else if (field.$error.seedingOverlapsHarvesting) {
                    message = gettextCatalog.getString("Selected date overlaps Harvesting Date");
                } else if (field.$error.seedingOverlapsAnotherCrop) {
                    message = gettextCatalog.getString("Selected date overlaps another crop");
                } else if (field.$error.seedingTooCloseToAnotherCrop) {
                    message = gettextCatalog.getString("Selected date is too close to another crop");
                } else if (field.$error.seedingDateMakeNewOutOfCrop) {
                    message = gettextCatalog.getString("Some on crop tasks became out of crop");
                } else if (field.$error.harvestingOverlapsSeeding) {
                    message = gettextCatalog.getString("Selected date overlaps Seeding Date");
                } else if (field.$error.harvestingOverlapsAnotherCrop) {
                    message = gettextCatalog.getString("Selected date overlaps with another crop");
                } else if (field.$error.harvestingTooCloseToAnotherCrop) {
                    message = gettextCatalog.getString("Selected date is too close to another crop");
                } else if (field.$error.harvestingTooCloseToSeedingDate) {
                    message = gettextCatalog.getString("Selected date is too close to Seeding Date");
                } else if (field.$error.harvestingTooFarFromSeedingDate) {
                    message = gettextCatalog.getString("Selected date is too far from Seeding Date");
                } else if (field.$error.harvestingDateMakeNewOutOfCrop) {
                    message = gettextCatalog.getString("Some on crop tasks became out of crop");
                } else {
                    if (!_.isEmpty(field.$error)) {
                        console.log(field.$error);
                    }
                }
            }

            return message;
        };

        vm.ok = function () {
            if ($scope.cropSettings.$invalid) {
                if ($scope.cropSettings.$error.required) {
                    $scope.cropSettings.$error.required.forEach(input => input.$setDirty());
                }

                return;
            }

            // Normalize dates
            vm.crop.seedingDate = _logicService.javascriptDateToMoment(vm.seedingDate.date).startOf('day').format(_logicService.DATETIME_FORMAT);
            vm.crop.plannedHarvestingDate = _logicService.javascriptDateToMoment(vm.plannedHarvestingDate.date).endOf('day').format(_logicService.DATETIME_FORMAT);
            vm.crop.actualHarvestingDate = vm.actualHarvestingDate.date ? _logicService.javascriptDateToMoment(vm.actualHarvestingDate.date).endOf('day').format(_logicService.DATETIME_FORMAT) : null;

            $uibModalInstance.close(vm.crop);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.editThresholds = function(type) {

            var theCrop = {
                id: crop.id,
                type: type,
                crop: crop
            };

            _view.setViewThresholds(theCrop);

            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('aside:opened');
        }
    }

    function cFieldPanelCropAdd($rootScope, $uibModal, _calendar, _apiService, Notification, _view, _mapService, _i18n, _logicService, gettextCatalog) {
        return {
            restrict: "E",
            templateUrl: "t-c-field-panel-crop-add",
            replace: true,
            scope: {
                f: "=",
                loading: "="
            },
            bindToController: true,
            controllerAs: "vm",
            controller: function($scope, $element) {
                var vm = this;

                $scope.$watch('vm.f.crops', function(newValue) {
                    if (newValue) {
                        init();
                    }
                }, true);

                $rootScope.$on("_calendar:changed", init);
                $rootScope.$on("_calendar:width-changed", init);

                function reposition() {

                    _calendar.getDisplayedDateBoundariesPromise().then(function(calendarBoundaries) {
                        if (calendarBoundaries == null) {
                            $element.css('display', 'none');
                            return;
                        }

                        // Iterate all crops and compare them with each other
                        var theLastCrop = _.reduce(vm.f.crops, function(previous, next){
                            return next.to.isAfter(previous.to) ? next: previous;
                        });

                        //  When the field has no any crops in it.
                        if (!theLastCrop) {
                            $element.css('left', '0%');
                            $element.css('width', '100%');
                            $element.css('display', 'block');
                        } else if (calendarBoundaries.to.isAfter(theLastCrop.to)) {

                            // First, display the button on the right
                            var buttonStart = moment.utc(theLastCrop.to).clone(); // start of the button equals the start of the last crop day

                            // There aren't any crops farther last crop
                            if (buttonStart.isBefore(calendarBoundaries.from)){
                                buttonStart = calendarBoundaries.from; // start of the button equals the start of the calendar
                            }

                            _calendar.getPositionForDatePromise(buttonStart).then(function(pos) {
                                if (pos != null) {
                                    $element.css('left', pos + '%');
                                    $element.css('width', (100 - pos) + '%');
                                    $element.css('display', 'block');
                                } else {
                                    $element.css('display', 'none');
                                }
                            });
                        } else {
                            $element.css('display', 'none');
                        }
                    });
                }

                function init() {
                    reposition();
                }

                vm.createCrop = function() {
                    $scope.settingsShown = true;

                    var $uibModalInstance = $uibModal.open({
                        openedClass: 'modal-open modal-cropSettings',
                        templateUrl: 'modal-cropSettings',
                        controllerAs: 'vm',
                        controller: ModalCropSettingsController,
                        resolve: {
                            crop: null,
                            crops: () => vm.f.crops,
                            fieldId: () => vm.f.id
                        }
                    });

                    $uibModalInstance.result.then(function (crop) {
                        vm.loading = true;

                        _apiService.createCrop(_view.getCurrentAccount(), crop).then(
                            response => {
                                vm.f.crops.push(processFieldVariety(response.data, _i18n, _logicService, gettextCatalog));

                                // Change field color on a map
                                _mapService.decorateField(vm.f.id, vm.f.crops);
                                _mapService.colorFields();

                                _view.setFieldCrops(true);

                                vm.loading = false;
                                Notification.success(_i18n.getString("crops.notifications.createCrop.success"));
                            },
                            () => {
                                vm.loading = false;
                                Notification.error(_i18n.getString("crops.notifications.createCrop.error"));
                            }
                        );

                        $scope.settingsShown = false;
                    }, function() {
                        $scope.settingsShown = false;
                    });
                };
            }
        };
    }

    function vSeedingDate($parse, $timeout, _logicService) {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function($scope, elm, attrs, input) {
                var directive = 'vSeedingDate';

                $timeout(function() {
                    input.$viewChangeListeners.push(function() {
                        var harvestingDates = $parse(attrs[directive])($scope);
                        harvestingDates.forEach(harvestingDate => harvestingDate.$validate());
                    });

                    /**
                     * Determine Whether Seeding Date Overlaps Harvesting Date
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.seedingOverlapsHarvesting = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        var seedingDate = _logicService.javascriptDateToMoment(modelValue);


                        var harvestingDates = $parse(attrs[directive])($scope);

                        var harvestingDate = null;
                        if (harvestingDates[0].$modelValue != null) {
                            harvestingDate = _logicService.javascriptDateToMoment(harvestingDates[0].$modelValue).endOf("day"); //actual
                        } else if (harvestingDates[1].$modelValue != null) {
                            harvestingDate = _logicService.javascriptDateToMoment(harvestingDates[1].$modelValue).endOf("day"); //planned
                        }

                        if (harvestingDate == null) {
                            return true;
                        }

                        return seedingDate.isBefore(harvestingDate);
                    };

                    /**
                     * Determine Whether Seeding Date coincides with another crop
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.seedingOverlapsAnotherCrop = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        var seedingDate = _logicService.javascriptDateToMoment(modelValue);
                        var harvestingDates = $parse(attrs[directive])($scope);

                        var harvestingDate = null;
                        if (harvestingDates[0].$modelValue != null) {
                            harvestingDate = _logicService.javascriptDateToMoment(harvestingDates[0].$modelValue).endOf("day"); //actual
                        } else if (harvestingDates[1].$modelValue != null) {
                            harvestingDate = _logicService.javascriptDateToMoment(harvestingDates[1].$modelValue).endOf("day"); //planned
                        }

                        if (harvestingDate == null) {
                            return true;
                        }

                        var crops = $scope.vm.crops.filter(crop => crop.id != $scope.vm.crop.id);

                        var result = _.some(crops, function(crop) {
                            var fromWithTasks = _logicService.cropFromWithTasks(crop);
                            var toWithTasks = _logicService.cropToWithTasks(crop);
                            return seedingDate.isBetween(fromWithTasks, toWithTasks, null, '()') ||
                                toWithTasks.isBetween(seedingDate, harvestingDate, null, '()') ||
                                fromWithTasks.isSame(seedingDate);
                        });

                        return !result;
                    };

                    /**
                     * Determine Whether a Seeding Date is too close to another crop
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.seedingTooCloseToAnotherCrop = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        var seedingDate = _logicService.javascriptDateToMoment(modelValue);
                        var crops = $scope.vm.crops.filter(crop => crop.id != $scope.vm.crop.id);

                        var result = _.some(crops, function(crop) {
                            var xDate = _logicService.cropToWithTasks(crop).clone().add(1, "day").startOf("day");
                            return seedingDate.isSame(xDate);
                        });

                        return !result;
                    };

                    input.$validators.seedingDateMakeNewOutOfCrop = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        var seedingDate = _logicService.javascriptDateToMoment(modelValue);
                        var result = false;

                        _.each($scope.vm.crop.tasks, function(task){
                            if (task.from.isSameOrAfter($scope.vm.crop.from) && task.from.isBefore(seedingDate)) {
                                result = true;
                            }
                        });

                        return !result;
                    };
                });
            }
        };
    }

    function vHarvestingDate($parse, $timeout, _logicService) {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function($scope, elm, attrs, input) {
                var directive = 'vHarvestingDate';

                $timeout(function() {
                    input.$viewChangeListeners.push(function () {
                        var seedingDate = $parse(attrs[directive])($scope);
                        seedingDate.$validate();
                    });

                    /**
                     * Determine Whether a Harvesting Date Overlaps Seeding Date
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.harvestingOverlapsSeeding = function (modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        if ($parse(attrs[directive])($scope).$modelValue == null) {
                            return true;
                        }

                        var harvestingDate = _logicService.javascriptDateToMoment(modelValue).endOf("day");
                        var seedingDate = _logicService.javascriptDateToMoment($parse(attrs[directive])($scope).$modelValue);

                        return harvestingDate.isAfter(seedingDate);
                    };

                    /**
                     * Determine Whether a Harvesting Date coincides with another crop
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.harvestingOverlapsAnotherCrop = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        if ($parse(attrs[directive])($scope).$modelValue == null) {
                            return true;
                        }

                        var seedingDate = _logicService.javascriptDateToMoment($parse(attrs[directive])($scope).$modelValue);
                        var harvestingDate = _logicService.javascriptDateToMoment(modelValue).endOf("day");
                        var crops = $scope.vm.crops.filter(crop => crop.id != $scope.vm.crop.id);

                        var result = _.some(crops, function(crop) {
                            var fromWithTasks = _logicService.cropFromWithTasks(crop);
                            var toWithTasks = _logicService.cropToWithTasks(crop);
                            return harvestingDate.isBetween(fromWithTasks, toWithTasks, null, '()') ||
                                fromWithTasks.isBetween(seedingDate, harvestingDate, null, '()') ||
                                toWithTasks.isSame(harvestingDate);
                        });

                        return !result;
                    };

                    /**
                     * Determine Whether a Seeding Date is too close to another crop
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.harvestingTooCloseToAnotherCrop = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }
                        var harvestingDate = _logicService.javascriptDateToMoment(modelValue).endOf("day");
                        var x_harvestingDate = harvestingDate.clone().add(1, "day").startOf("day");
                        var crops = $scope.vm.crops.filter(crop => crop.id != $scope.vm.crop.id);

                        var result = _.some(crops, function(crop) {
                            var xDate = _logicService.cropFromWithTasks(crop);
                            return x_harvestingDate.isSame(xDate);
                        });

                        return !result;
                    };

                    /**
                     * Determine Whether a Harvesting Date is too close to another crop
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.harvestingTooCloseToSeedingDate = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        if ($parse(attrs[directive])($scope).$modelValue == null) {
                            return true;
                        }

                        var harvestingDate = _logicService.javascriptDateToMoment(modelValue).endOf("day");
                        var seedingDate = _logicService.javascriptDateToMoment($parse(attrs[directive])($scope).$modelValue);

                        return harvestingDate.diff(seedingDate, 'month') >= 1;
                    };

                    /**
                     * Determine Whether a Harvesting Date is too close to another crop
                     *
                     * @param modelValue
                     * @returns {boolean}
                     */
                    input.$validators.harvestingTooFarFromSeedingDate = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        if ($parse(attrs[directive])($scope).$modelValue == null) {
                            return true;
                        }

                        var harvestingDate = _logicService.javascriptDateToMoment(modelValue).endOf("day");
                        var seedingDate = _logicService.javascriptDateToMoment($parse(attrs[directive])($scope).$modelValue);

                        return harvestingDate.diff(seedingDate, 'days') <= 512;
                    };

                    input.$validators.harvestingDateMakeNewOutOfCrop = function(modelValue) {
                        // consider empty model to be valid
                        if (input.$isEmpty(modelValue)) {
                            return true;
                        }

                        if (!attrs[directive]) {
                            return false;
                        }

                        var harvestingDate = _logicService.javascriptDateToMoment(modelValue).endOf("day");
                        var result = false;

                        _.each($scope.vm.crop.tasks, function(task){
                            if (task.to.isSameOrBefore($scope.vm.crop.to) && task.to.isAfter(harvestingDate)) {
                                result = true;
                            }
                        });

                        return !result;
                    };
                });
            }
        };
    }
}(angular));